import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { CBOShoppableVideoListItemReadModel } from '../../../domain/CBOShoppableVideoReadModel';
import { createShoppable } from '../../../usecases/shoppables/create-shoppable';
import { deleteShoppable } from '../../../usecases/shoppables/delete-shoppable';
import { ShoppablesState } from '../../state/shoppables';
import { errorStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetCreateShoppable = createAction<void>('shoppables/reset-create-shoppable');
export const resetDeleteShoppable = createAction<void>('shoppables/reset-delete-shoppable');

export const buildCrudReducers = (builder: ActionReducerMapBuilder<ShoppablesState>) => {
  builder.addCase(createShoppable.fulfilled, (state, action) => {
    const readModel = action.payload;
    const listReadModel: CBOShoppableVideoListItemReadModel = {
      createdAt: readModel.createdAt,
      id: readModel.id,
      productId: readModel.productId,
      productImage: readModel.productImage,
      productOriginalId: readModel.productOriginalId,
      productTitle: readModel.productTitle,
      status: readModel.status,
      thumbnailUrl: readModel.thumbnailUrl,
      title: readModel.title,
      videoDuration: readModel.videoDuration,
      videoUrl: readModel.videoUrl,
    };

    const shoppables = [...state.shoppables];
    shoppables.unshift(listReadModel);

    return {
      ...state,
      ...successStatus('shoppableCreation'),
      currentShoppable: readModel,
      shoppables,
    };
  });
  builder.addCase(createShoppable.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppableCreation'),
  }));
  builder.addCase(createShoppable.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppableCreation', [action.error]),
  }));
  builder.addCase(deleteShoppable.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('shoppableDeletion'),
    currentShoppable: state.currentShoppable?.id === action.meta.arg.shoppableId ? null : state.currentShoppable,
    shoppables: state.shoppables.filter((shoppable) => shoppable.id !== action.meta.arg.shoppableId),
  }));
  builder.addCase(deleteShoppable.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppableDeletion'),
  }));
  builder.addCase(deleteShoppable.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppableDeletion', [action.error]),
  }));
  builder.addCase(resetCreateShoppable, (state) => ({
    ...state,
    ...notRequestedStatus('shoppableCreation'),
  }));
  builder.addCase(resetDeleteShoppable, (state) => ({
    ...state,
    ...notRequestedStatus('shoppableDeletion'),
  }));
};
