import { EventId, EventStatus } from '@bellepoque/api-contracts';
import { ContentCopy, Info } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography, styled, useTheme } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../../../core/domain/CBOEventListReadModel';
import { CBOLandingPageData } from '../../../../core/domain/CBOLandingPageData';
import { eventStatusToTranslation } from '../../../../utils/event-status-to-translation';
import { makeAutoplayUrl } from '../../../../utils/replay-links';
import CreateButton from '../../atoms/CreateButton';
import CustomLoadingGridOverlay from '../../atoms/CustomLoadingGridOverlay';
import EventDateValue from '../../atoms/EventDateValue';
import DownloadReplayButton from '../../atoms/event/DownloadReplayButton';

const PREFIX = 'EventsListGrid';

const classes = {
  boldCell: `${PREFIX}-bold-cell`,
  cell: `${PREFIX}-cell`,
  gridRow: `${PREFIX}-grid-row`,
  headerAlignCenter: `${PREFIX}-header-align-center`,
  hidden: `${PREFIX}-hidden`,
  mediumCell: `${PREFIX}-medium-cell`,
};

const Container = styled(Box)(({ theme }) => ({
  ['.MuiDataGrid-cell:focus']: {
    outline: 'none',
  },
  ['.MuiDataGrid-cell:focus-within']: {
    outline: 'none',
  },
  [`.MuiDataGrid-columnHeaderTitleContainer`]: {
    justifyContent: 'flex-start',
    paddingLeft: 0,
  },
  [`& .${classes.headerAlignCenter}`]: {
    justifyContent: 'center',
  },
  [`.MuiDataGrid-columnSeparator`]: {
    visibility: 'hidden',
  },
  [`.MuiDataGrid-columnsContainer`]: {
    border: 'none',
  },

  border: 'none',
  fontFamily: theme.typography.h1.fontFamily,
  height: '100%',
  [`& .${classes.gridRow} > .${classes.cell}`]: {
    border: 'none',
    cursor: 'default',
    outline: 'none',
    padding: `0 ${theme.spacing(2)}`,
  },
  [`& .${classes.boldCell}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.gridRow}`]: {
    '&:nth-child(2n-1)': {
      background: theme.palette.common.white,
    },
    cursor: 'pointer',
  },
  [`& .${classes.mediumCell}`]: {
    fontWeight: 500,
  },
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}));

const Image = styled('img')({
  borderRadius: '10px',
  height: '50px',
  objectFit: 'cover',
  width: '50px',
});

const NoRowsMessageContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
});

type EventListGridProps = {
  canShareEvents: boolean;
  events: CBOEventListReadModel[];
  eventsType: 'finished' | 'upcoming';
  landingPageData: CBOLandingPageData;
  onCreateEvent?: () => void;
  onShowEvent: (id: EventId) => void;
};

export default function EventListGrid(props: EventListGridProps) {
  const { canShareEvents, events, eventsType, landingPageData, onCreateEvent, onShowEvent } = props;
  const { t } = useTranslation('events');
  const { t: eventStatusTranslator } = useTranslation('events', { keyPrefix: 'status' });
  const theme = useTheme();

  const [copiedEventId, setCopiedEventId] = useState<string | null>(null);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'showTime',
      sort: 'desc',
    },
  ]);

  useEffect(() => {
    if (copiedEventId) {
      setTimeout(() => {
        setCopiedEventId(null);
      }, 1500);
    }
  }, [copiedEventId]);

  const handleCopyEventLink = (eventId: EventId) => {
    if (!landingPageData.landingPageUrl) {
      return;
    }

    navigator.clipboard.writeText(makeAutoplayUrl({ baseUrl: landingPageData.landingPageUrl, eventId }));
    setCopiedEventId(eventId);
  };

  const defaultHeaderProps: Partial<GridColDef> = {
    flex: 1,
    sortable: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: '',
      renderCell: (params: GridCellParams<unknown, CBOEventListReadModel>) => {
        const preEventCoverUrl = params.row.image;
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              lineHeight: 'initial',
            }}
          >
            {preEventCoverUrl && <Image src={preEventCoverUrl} />}
          </Box>
        );
      },
      sortable: false,
    },
    {
      ...defaultHeaderProps,
      field: 'showTime',
      headerName: t('Date'),
      renderCell: (params: GridCellParams<unknown, CBOEventListReadModel>) => {
        return (
          <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, lineHeight: 'initial' }}>
            <EventDateValue date={params.row.showTime} />
          </Box>
        );
      },
    },
    {
      ...defaultHeaderProps,
      cellClassName: classes.boldCell,
      field: 'title',
      headerName: t('Title'),
    },
    {
      ...defaultHeaderProps,
      field: 'publisherPhone',
      headerName: t('Publisher'),
      valueFormatter: (params: GridValueFormatterParams) => params.value ?? t('common:NotApplicable'),
    },
    {
      ...defaultHeaderProps,
      field: 'status',
      flex: 0,
      headerName: t('Status'),
      valueFormatter: (params: GridValueFormatterParams) =>
        eventStatusToTranslation(params.value as EventStatus, eventStatusTranslator),
      width: 125,
    },
    {
      ...defaultHeaderProps,
      align: 'center',
      field: 'productsCount',
      flex: 0,
      headerAlign: 'center',
      headerName: t('ProductsCount'),
      valueGetter: (params: GridValueGetterParams) => `${(params.row as CBOEventListReadModel).productsIds.length}`,
      width: 100,
    },
    {
      ...defaultHeaderProps,
      align: 'center',
      field: 'remindersCount',
      headerAlign: 'center',
      headerName: t('Reminders'),
      hide: eventsType === 'finished',
      valueGetter: (params: GridValueGetterParams) => {
        const event = params.row as CBOEventListReadModel;
        return event.status === EventStatus.DRAFT ? t('common:NotApplicable') : event.remindersCount;
      },
    },
    {
      ...defaultHeaderProps,
      field: 'share',
      flex: 0,
      headerName: t('LinkToEvent'),
      hide: !landingPageData.landingPageUrl,
      renderCell: (params: GridCellParams<unknown, CBOEventListReadModel>) => {
        if (!canShareEvents) {
          return null;
        }

        const event = params.row;

        const isLiveEventPublished = eventsType === 'upcoming' && landingPageData.highlightedEventId === event.id;
        const isReplayPublished =
          eventsType === 'finished' && landingPageData.publishedReplayEventsIds.includes(params.row.id);

        const isShown = isLiveEventPublished || isReplayPublished;

        if (!isShown) {
          return null;
        }

        return (
          <Box alignItems="center" display="flex" flex={1} justifyContent="center">
            <Tooltip
              arrow
              placement="bottom"
              title={t(copiedEventId === event.id ? 'common:LinkCopied' : 'common:CopyLink').toString()}
            >
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyEventLink(event.id);
                }}
                sx={{ opacity: 1 }}
              >
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      renderHeader: () => {
        return (
          <Box alignItems="center" className={classes.headerAlignCenter} display="flex" justifyContent="center">
            <Typography fontSize="1em" fontWeight={500}>
              {t(eventsType === 'upcoming' ? 'LinkToEvent' : 'LinkToReplay')}
            </Typography>
            <Tooltip title={t(eventsType === 'upcoming' ? 'LinkToEventTooltip' : 'LinkToReplayTooltip').toString()}>
              <IconButton>
                <Info fontSize="small" htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      sortable: false,
      width: 150,
    },
    {
      ...defaultHeaderProps,
      field: 'replay-file',
      headerName: '',
      hide: eventsType === 'upcoming',
      renderCell: (params: GridCellParams<unknown, CBOEventListReadModel>) => {
        const replayFileUrl = params.row.replayFileUrl;
        return replayFileUrl ? <DownloadReplayButton link={replayFileUrl} /> : null;
      },
    },
  ];

  return (
    <Container>
      <DataGrid
        autoHeight
        columns={columns}
        components={{
          LoadingOverlay: CustomLoadingGridOverlay,
          NoRowsOverlay: onCreateEvent
            ? () => (
                <NoRowsMessageContainer>
                  <Box display="flex" flexDirection="column">
                    <Typography>{t('NoEventsFound')}</Typography>
                    <CreateButton onClick={onCreateEvent} sx={{ mt: 1 }} text={t('CreateAnEvent')} />
                  </Box>
                </NoRowsMessageContainer>
              )
            : undefined,
        }}
        disableColumnMenu
        getRowClassName={() => classes.gridRow}
        hideFooter
        onRowClick={({ row }) => onShowEvent(row.id)}
        onSortModelChange={setSortModel}
        rowHeight={100}
        rows={events}
        sortModel={sortModel}
        style={{
          border: 'none',
          fontFamily: theme.typography.h1.fontFamily,
          height: '100%',
        }}
      />
    </Container>
  );
}
